import React from 'react';
import ImportSingle from '../components/ImportSingle';
import ImportBulk from '../components/ImportBulk';
import Queue from '../components/Queue';

const Home = () => (
  <div style={{ padding: '10px 20px' }}>
    <ImportSingle />
    <ImportBulk />
    <Queue />
  </div>
);

export default Home;
