import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Heading, Pane, Text, Button, Spinner, TextInput, Select, Pre, TextInputField,
} from 'evergreen-ui';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import {
  loginGoogle,
  queueTransfer,
  queueClear,
  queueBackup,
  queueMerge,
  queueSort,
  queueFilterByTitle,
  loginDropbox,
} from '../../apiClient';

import QueueTable from './QueueTable';
import { QUEUE_UPDATED, GET_QUEUE } from '../../graphql';

const calcTotalTimeFromItems = items => items.reduce((total, item) => {
  if (item.status !== 'uploaded' && item.status !== 'backedUp' && item.status !== 'error') {
    return total + Number(item.delay);
  }
  return total;
}, 0);

class Queue extends Component {
  state = {
    filterByTitle: '',
    sortBy: 'publishedAt',
    orderBy: 'asc',
    allDelays: 2,
  };

  componentDidMount() {
    const startDropbox = localStorage.getItem('startCopyDropbox');
    if (startDropbox) {
      queueBackup().catch(() => {});
      this.removeStartCopyDropbox();
    }
  }

  saveStartCopyDropbox = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('startCopyDropbox', 'true');
    }
  };

  removeStartCopyDropbox = () => {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('startCopyDropbox');
    }
  };

  render() {
    const { filterByTitle, sortBy, orderBy, allDelays } = this.state;
    const { googleProfile, dropboxProfile } = this.props;
    return (
      <Query query={GET_QUEUE}>
        {({
          data, loading, error, subscribeToMore,
        }) => {
          if (loading) {
            return <Spinner />;
          }
          if (error) {
            return <Pre>{error.message}</Pre>;
          }
          const { getQueue } = data;
          const { inProgress, youtubeAccount } = getQueue.info;
          subscribeToMore({
            document: QUEUE_UPDATED,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return {
                getQueue: subscriptionData.data.queueUpdated,
              };
            },
          });
          return (
            <div>
              <Pane display="flex" flexDirection="row" alignItems="baseline" marginBottom={10}>
                {inProgress ? (
                  <Heading color="green" size={600} marginRight={20}>
                    Queue in progress
                    {inProgress && youtubeAccount && `, uploading to '${youtubeAccount}'`}
                  </Heading>
                ) : (
                  <Heading size={600} marginRight={20}>
                    Queue
                  </Heading>
                )}
                <Text marginRight={20}>
                  Estimated time: ~{calcTotalTimeFromItems(getQueue.items)}min
                </Text>
              </Pane>
              <Pane marginBottom={10}>
                <Button
                  onClick={() => {
                    if (googleProfile) {
                      queueTransfer().catch(() => {});
                    } else {
                      loginGoogle();
                    }
                  }}
                  iconBefore="play"
                  intent="success"
                  appearance="primary"
                  marginRight={10}
                  disabled={inProgress}
                >
                  Copy to Youtube
                </Button>
                <Button
                  onClick={async () => {
                    await queueMerge();
                  }}
                  iconBefore="play"
                  intent="warning"
                  appearance="primary"
                  marginRight={10}
                  disabled={inProgress}
                >
                  Merge
                </Button>
                <Button
                  onClick={() => {
                    if (dropboxProfile) {
                      queueBackup().catch(() => {});
                    } else {
                      this.saveStartCopyDropbox();
                      loginDropbox();
                    }
                  }}
                  iconBefore="play"
                  intent="success"
                  appearance="primary"
                  marginRight={10}
                  disabled={inProgress}
                >
                  Copy to Dropbox
                </Button>
                <Button
                  onClick={async () => {
                    await queueClear();
                  }}
                  iconBefore="trash"
                  intent="danger"
                  appearance="primary"
                  marginRight={10}
                >
                  Stop & Delete Queue
                </Button>
              </Pane>
              <Pane justifyContent="center" alignItems="center" marginBottom={10}>
                <Text marginRight={10}>Filter by title</Text>
                <TextInput
                  onChange={e => this.setState({ filterByTitle: e.target.value })}
                  width={220}
                  value={filterByTitle}
                  marginRight={4}
                  disabled={inProgress}
                />
                <Button
                  disabled={filterByTitle === '' || inProgress}
                  onClick={() => queueFilterByTitle(filterByTitle)}
                  marginRight={20}
                >
                  Filter
                </Button>
                <Text marginRight={10}>Sort by</Text>
                <Select
                  onChange={(e) => {
                    const newSortBy = e.target.value;
                    this.setState({ sortBy: newSortBy });
                    queueSort(newSortBy, orderBy).catch(() => {});
                  }}
                  width={130}
                  marginRight={20}
                  disabled={inProgress}
                >
                  <option value="publishedAt">Published At</option>
                  <option value="viewCount">View count</option>
                </Select>
                <Text marginRight={10}>Order by</Text>
                <Select
                  onChange={(e) => {
                    const newOrderBy = e.target.value;
                    this.setState({ orderBy: newOrderBy });
                    queueSort(sortBy, newOrderBy).catch(() => {});
                  }}
                  width={80}
                  disabled={inProgress}
                >
                  <option value="asc">ASC</option>
                  <option value="desc">DESC</option>
                </Select>
                {/* <Pane display="flex" flexDirection="row">
                  <TextInputField
                    marginBottom={10}
                    width={34}
                    label="Delay"
                    value={allDelays}
                    onChange={e => setCustomDelay(e.target.value)}
                    marginRight={10}
                    disabled={index === 0 || inProgress}
                  />
                  <Pane marginTop={24}>
                    {index !== 0 && (
                      <Button
                        marginRight={10}
                        appearance="primary"
                        onClick={() => {
                          setLoadingCustomDelay(true);
                          queueSetItemDelay(id, customDelay)
                            .catch(() => {})
                            .finally(() => setLoadingCustomDelay(false));
                        }}
                        isLoading={loadingCustomDelay}
                        disabled={inProgress}
                      >
                        Set all delays
                      </Button>
                    )}
                    {index !== 0 && customDelay !== 2 && (
                      <Button
                        onClick={() => {
                          setLoadingCustomDelay(true);
                          queueSetItemDelay(id, 2)
                            .catch(() => {})
                            .finally(() => {
                              setCustomDelay(2);
                              setLoadingCustomDelay(false);
                            });
                        }}
                        isLoading={loadingCustomDelay}
                        disabled={inProgress}
                      >
                        Reset
                      </Button>
                    )}
                  </Pane>
                </Pane> */}
              </Pane>
              <QueueTable items={getQueue.items} inProgress={inProgress} />
            </div>
          );
        }}
      </Query>
    );
  }
}

Queue.propTypes = {
  googleProfile: PropTypes.shape({
    picture: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  dropboxProfile: PropTypes.shape(),
};

const mapStateToProps = ({ user }) => ({
  googleProfile: user.googleProfile,
  dropboxProfile: user.dropboxProfile,
});

export default connect(mapStateToProps)(Queue);
