import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'evergreen-ui';
import QueueItem from './QueueItem';

const QueueTable = ({ items = [], inProgress }) => (
  <Table>
    <Table.Head>
      <Table.TextCell flexBasis={120} flexShrink={0} flexGrow={0}>Status</Table.TextCell>
      <Table.TextCell flexBasis={200} flexShrink={0} flexGrow={0}>Delay</Table.TextCell>
      <Table.TextCell>
        Video details
      </Table.TextCell>
      <Table.TextCell>Edit</Table.TextCell>
    </Table.Head>
    <Table.Body>
      {items.map((item, i) => (
        <QueueItem inProgress={inProgress} item={item} index={i} key={item.id} />
      ))}
    </Table.Body>
  </Table>
);

QueueTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      metadata: PropTypes.shape().isRequired,
      id: PropTypes.string.isRequired,
      delay: PropTypes.number.isRequired,
    }),
  ).isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default QueueTable;
