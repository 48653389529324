import { auth } from '../App';

export const user = {
  state: {
    isLoggedIn: false,
    profile: null,
    googleProfile: null,
    isLoggingWithGoogle: false,
    dropboxProfile: null,
    isLoggingWithDropbox: false,
  },
  reducers: {
    setLogggedIn(state, isLoggedIn) {
      return { ...state, isLoggedIn };
    },
    setProfile(state, profile) {
      return { ...state, profile };
    },
    setGoogleProfile(state, googleProfile) {
      return { ...state, googleProfile, isLoggingWithGoogle: false };
    },
    setIsLoggingWithGoogle(state, isLoggingWithGoogle) {
      return { ...state, isLoggingWithGoogle };
    },
    setDropboxProfile(state, dropboxProfile) {
      return { ...state, dropboxProfile, isLoggingWithDropbox: false };
    },
    setIsLoggingWithDropbox(state, isLoggingWithDropbox) {
      return { ...state, isLoggingWithDropbox };
    },
  },
  effects: dispatch => ({
    async getProfile() {
      const profile = await auth.getProfile();
      dispatch.user.setProfile(profile);
    },
  }),
};
