import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Pane, Button, Text, Avatar, Spinner, Strong,
} from 'evergreen-ui';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { auth } from '../App';
import { logoutGoogle, logoutDropbox } from '../apiClient';

const GET_QUEUE_STATUS = gql`
  query getQueue {
    getQueue {
      info {
        inProgress
      }
    }
  }
`;

const Header = ({
  isLoggedIn,
  profile,
  googleProfile,
  dropboxProfile,
  isLoggingWithDropbox,
  isLoggingWithGoogle,
}) => {
  const [disconnectingGoogle, setDisconnectGoogle] = useState(false);
  const [disconnectingDropbox, setDisconnectDropbox] = useState(false);
  if (!isLoggedIn) {
    return (
      <Pane
        backgroundColor="#fafafa"
        padding="15px"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button appearance="primary" onClick={() => auth.login()}>
          Login
        </Button>
      </Pane>
    );
  }

  return (
    <Pane backgroundColor="#fafafa" padding="15px" display="flex" alignItems="center">
      <Query query={GET_QUEUE_STATUS}>
        {({ data }) => (
          <Pane display="flex" alignItems="center">
            {(isLoggingWithGoogle || isLoggingWithDropbox) && (
              <>
                <Spinner size={20} marginRight={10} />
                <Text marginRight={20}>Checking logged in accounts</Text>
              </>
            )}
            {googleProfile && (
              <>
                <Strong marginRight={10} color="#ff0000">
                  Youtube:
                </Strong>
                <Avatar marginRight={10} name={googleProfile.name} src={googleProfile.picture} />
                <Text marginRight={10}>{googleProfile.name}</Text>
                <Button
                  isLoading={disconnectingGoogle}
                  disabled={data && data.getQueue && data.getQueue.info.inProgress}
                  onClick={async () => {
                    setDisconnectGoogle(true);
                    try {
                      await logoutGoogle(googleProfile.sub);
                      await auth.checkLoggedInAccounts();
                    } catch (err) {
                      console.log(err);
                    } finally {
                      setDisconnectGoogle(false);
                    }
                  }}
                  marginRight={20}
                >
                  Disconnect
                </Button>
              </>
            )}
            {dropboxProfile && (
              <>
                <Strong marginRight={10} color="#007ee5">
                  Dropbox account:
                </Strong>
                <Avatar
                  marginRight={10}
                  name={dropboxProfile.email}
                  src={dropboxProfile.profile_photo_url}
                />
                <Text marginRight={10}>{dropboxProfile.name.display_name}</Text>
                <Button
                  isLoading={disconnectingDropbox}
                  disabled={data && data.getQueue && data.getQueue.info.inProgress}
                  onClick={async () => {
                    setDisconnectDropbox(true);
                    try {
                      await logoutDropbox(dropboxProfile.account_id);
                      await auth.checkLoggedInAccounts();
                    } catch (err) {
                      console.log(err);
                    } finally {
                      setDisconnectDropbox(false);
                    }
                  }}
                  marginRight={20}
                >
                  Disconnect
                </Button>
              </>
            )}
          </Pane>
        )}
      </Query>
      <Pane flex={1} display="flex" justifyContent="flex-end" alignItems="center">
        {profile && (
          <>
            <Avatar marginRight={10} name={profile.email} src={profile.picture} />
            <Text marginRight={10}>{profile.email}</Text>
          </>
        )}
        <Button onClick={() => auth.logout()}>Logout</Button>
      </Pane>
    </Pane>
  );
};

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    avatar: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
  googleProfile: PropTypes.shape({
    picture: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }),
  isLoggingWithGoogle: PropTypes.bool.isRequired,
  dropboxProfile: PropTypes.shape(),
  isLoggingWithDropbox: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ user }) => ({
  isLoggedIn: user.isLoggedIn,
  profile: user.profile,
  googleProfile: user.googleProfile,
  dropboxProfile: user.dropboxProfile,
  isLoggingWithDropbox: user.isLoggingWithDropbox,
  isLoggingWithGoogle: user.isLoggingWithGoogle,
});

export default connect(mapStateToProps)(Header);
